export const reviews = [
  {
    id: 1,
      // 'https://images.unsplash.com/photo-1605462863863-10d9e47e15ee?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    name:  `Mr Oluwaloni Familoni`,
    text: `The most amazing thing about the Solaris solar system is that even after 4 years of using it... It's still as effective as ever!
    Then the icing on the cake is their amazing team... Very professional and efficient.
    They know what they're doing. I highly recommend!`,
  },
  {
    id: 2,
    name: `Miss Temidayo Abass`,
    text: `Excellent customer service. Despite the small team, Solaris took the time to figure out our electricity needs and address them adequately. Not lacking at all`
  },
  {
    id: 3,
    name: 'Mr Babajide Oyekunle',
    text: `I'm quite happy to write this. I got a 5KVA solar system from them a while ago and the entire experience has been great. Great customer service too`  },
  {
    id: 4,
    name: 'Mrs Chinenye Obiekwe',
    text: `Durable, Reliable and professional.

    Would totally recommend.`,
  },
  {
    id: 5,
    name: 'Modupe Abidakun',
    text: `I absolutely love my 2.5 kVA solar system, thanks to Solaris! Their team delivered top-tier products with unparalleled expertise, transparency, and punctuality. From the solar panels to the batteries, everything's equally sleek and solid. Choosing Solaris is a no-brainer for quality and reliability, and I 100% recommend!`  },
];
