import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import pic1 from '../../assets/gallery/1.png'
import pic2 from '../../assets/gallery/2.png'
import pic3 from '../../assets/gallery/3.png'
import pic4 from '../../assets/gallery/4.png'
import pic5 from '../../assets/gallery/5.png'
import pic6 from '../../assets/gallery/6.png'
import pic7 from '../../assets/gallery/7.png'
import pic8 from '../../assets/gallery/8.png'
import pic9 from '../../assets/gallery/9.png'
import pic10 from '../../assets/gallery/10.jpeg'
import pic11 from '../../assets/gallery/11.jpeg'
import pic12 from '../../assets/gallery/12.jpeg'
import pic13 from '../../assets/gallery/13.jpeg'
import pic14 from '../../assets/gallery/14.jpeg'
import pic15 from '../../assets/gallery/15.jpeg'
import pic16 from '../../assets/gallery/16.jpeg'
import pic17 from '../../assets/gallery/17.jpeg'
import pic18 from '../../assets/gallery/18.jpeg'
import pic19 from '../../assets/gallery/19.jpeg'
import pic20 from '../../assets/gallery/20.jpeg'
import pic21 from '../../assets/gallery/21.JPG'
import pic22 from '../../assets/gallery/22.jpeg'
import pic23 from '../../assets/gallery/23.jpeg'

const Gallery = () => {
  return (

    <div className="gallery-container py-4">
      <div className="title mb-3">
        <h1>Gallery</h1>
      </div>
      <Splide
        options={{
          rewind: true,
          gap: '1rem',
          perPage: 2,
          autoplay: true,
          rewindByDrag: true,
          speed: 2000,
        }}
        // style={{ width: '100rem', maxWidth: '100%', margin: '0 auto' }}
      >
        {/* Map through your image sources and render SplideSlides */}
        {[pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8, pic9, pic10, pic11, pic12, pic13, pic14, pic15, pic16, pic17, pic18, pic19, pic20, pic21, pic22, pic23].map((pic, index) => (
          <SplideSlide key={index}>
            <img src={pic} alt="gallery" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}

export default Gallery;