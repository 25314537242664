import React from "react";
import { Route, Routes as Switch } from "react-router-dom";
import Solar from "../pages/Solar";
// import Home from "../pages/Home";
// import Cars from "../pages/Cars";
// import NotFound from "../pages/NotFound";
// import Auth from "../pages/Auth";
// import Shop from "../pages/Shop";
// import ShopMain from "../components/shop/ShopMain";
// import ShopDetails from "../components/shop/ShopDetails";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" element={<Solar />}  />
    </Switch>
  );
};

export default Routes;
