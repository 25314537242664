import React, { useContext, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { getSolarModel } from "../../api/api";
import Loading from "../loading/Loading";
import "./solar-model.scss";
import { MenuContext } from "../../contexts/menu-context";
import videoHero from "../../assets/solar/close-up-solar-panel.mp4";
import Testimonials from "../testimonials/Testimonials";
import Gallery from "../gallery/Gallery";
import { Link, animateScroll as scroll } from "react-scroll";

const Solar = () => {
  const { setIsDark } = useContext(MenuContext);
  const [solar, setSolar] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const sendRequest = async () => {
      setIsLoading(true);
      setSolar(await getSolarModel(id));
      setIsLoading(false);
    };
    sendRequest();

    setIsDark(solar.isDark);

    return () => {
      setIsDark(false);
    };
  }, [id, setIsDark, solar.isDark]);

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && solar.name && <SolarItem solar={solar} />}
    </>
  );
};

const SolarItem = ({ solar }) => {
  const { isMenuShown } = useContext(MenuContext);
  const divRef = useRef();
  const isTablet = useMediaQuery("(max-width: 825px)");
  const imgHero = isTablet ? solar.hero_img_mobile : solar.hero_img_desktop;

  useEffect(() => {
    if (isMenuShown) {
      divRef.current.classList.add("darken");
    } else {
      divRef.current.classList.remove("darken");
    }
  }, [isMenuShown]);

  return (
    <div className="solar" ref={divRef}>
      {/* <div
        className="solar__hero"
        // style={{
        //   backgroundImage: `url(${require("../../assets/solar/" + imgHero)})`,
        // }}
      > */}

      <div className="solar__hero">
        <video
          className="solar__hero-video"
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline="true"
          // You might want to add additional attributes like controls if you want to enable playback controls
        >
          <source src={videoHero} type="video/mp4" />
          {/* Add additional source tags for different video formats if needed */}
          Your browser does not support the video tag.
        </video>

        <div
          className="solar__hero__info"
          style={solar.isDark ? { color: "#fff" } : {}}
        >
          {/* <video id="background-video" autoPlay loop muted>
            <source src={"../../assets/solar/" + imgHero} type="video/mp4"/>
          </video> */}
          <motion.h1
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            {solar.heading}
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.75 }}
          >
            {solar.description}
          </motion.p>
        </div>

        <div className="solar__hero__specs">
          <SpecsItem
            title="&#8358;"
            label="Guaranteed Lowest Price in Nigeria"
            delay={0.1}
          />
          <SpecsItem title="⚡" label="Best Solar Technology" delay={0.33} />

          <SpecsItem
            title="🛡️"
            label="Amazing Customer Experience"
            delay={0.67}
          />

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1 }}
            className="solar__hero__specs__item"
          >
            <Link
                activeClass="active"
                to="contacts"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {1000}
            >
              <button className="btn-primary">ORDER NOW</button>
            </Link>
            
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1.33 }}
            className="solar__hero__specs__item"
          >
            <Link
                activeClass="active"
                to="contacts"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {1000}
            >
               <button className="btn-primary btn-primary-inverted">
              GET A FREE CONSULTATION
            </button>
            </Link>
           
          </motion.div>
        </div>
      </div>

      <div className="solar__section">
        <div
          className="solar__section__cover"
          style={{
            backgroundImage: `url(${require("../../assets/solar/solar-rooftop-sunset.jpg")})`,
          }}
        ></div>
      </div>

      <div className="solar__about">
        <div className="solar__about__media" style={{ order: 1 }}>
          <img src={require(`../../assets/solar/inverter-overlay.jpg`)} alt="about" />
        </div>

        <motion.div
          className="solar__about__info"
          initial={{
            y: "20%",
            opacity: 0,
          }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <div className="solar__about__info__text">
            {/* <h3>{solar.about_type}</h3> */}
            <h2>{solar.about_title}</h2>
            <p>
              Keep your home or business running 24/7 with solar energy and
              reduce your dependence on unreliable power sources. With{" "}
              <u>Solaris’ Best Price Guarantee</u>, you’ll purchase solar at the
              lowest price in Nigeria. <Link
              activeClass="active"
              to="contacts"
              spy={true}
              smooth={true}
              offset={-70}
              duration= {1000}
          >
          <u style={{
            cursor: "pointer"
          }}>Schedule a free consultation</u> 
          </Link>to
              learn more.
            </p>
          </div>

          <div className="solar__about__info__btn  center">
          <Link
                activeClass="active"
                to="contacts"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {1000}
            >
              <button className="btn-secondary">ORDER NOW</button>
            </Link>
            
          </div>
        </motion.div>
      </div>

      <div
          className="solar__section__cover"
          style={{
            background: "#000",
          }}
        >

          <Testimonials /> 
          
        </div>

        <div
          className="solar__section__cover"
          style={{
            background: "#000",
          }}
        >

          <Gallery /> 
          
        </div>
      <div className="solar__about">
      <div className="solar__about__media">
          <img
            src={require(`../../assets/solar/solar-tech.gif`)}
            alt="about"
          />
        </div>
     

        <motion.div
          className="solar__about__info"
          initial={{
            y: "20%",
            opacity: 0,
          }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <div className="solar__about__info__text">
            <h2>First Class Technology</h2>
            <p>
              Solar Panels that are highly efficient, Batteries and Inverters
              that are reliable and durable long term. 365 days a year of
              maximum power production and effortless comfort.{" "}
            </p>
          </div>

          <div className="solar__about__info__btn center">
          <Link
                activeClass="active"
                to="contacts"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {1000}
            >
              <button className="btn-secondary">ORDER NOW</button>
            </Link>
            
          </div>
        </motion.div>
      </div>

      <div className="solar__section pb-0">
        {/* <div
          className="solar__section__cover"
          style={{
            backgroundImage: `url(${require("../../assets/solar/battery-desktop.jpg")})`,
          }}
        ></div> */}

        <SectionSpecs
          orderNow={false}
          title="Reliable brands"
          description=""
          brands={true}
        />
      </div>

      <div className="solar__specs">
        <div className="solar__specs__container">
          <div className="solar__specs__container__img">
            <img
              src={require(`../../assets/solar/${solar.specs_img}`)}
              alt="solar item"
            />
          </div>

          <motion.div
            className="solar__specs__container__specs"
            initial={{
              y: "50%",
              opacity: 0,
            }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <div className="solar__specs__container__specs__title">
              <h2>
                {solar.name} <span>Specs</span>
              </h2>
            </div>

            <div className="solar__specs__container__specs__details">
              <SpecsDetailsItem title="Wattage" specs={solar.wattage} />
              <SpecsDetailsItem
                title="Operating Temperature"
                specs={solar.temperature}
              />
              <SpecsDetailsItem title="Dimensions" specs={solar.dimensions} />
              <SpecsDetailsItem title="Design" specs={solar.design} />
              <SpecsDetailsItem title="Warranty" specs={solar.warranty} />
              <SpecsDetailsItem
                title="Certifications"
                specs={solar.certification}
              />
              <SpecsDetailsItem title="Inverter Power" specs={solar.power} />
              <SpecsDetailsItem title="Fire Rating" specs={solar.fire} />
            </div>
          </motion.div>
        </div>
      </div>
     

      {<div className="solar__about">
        <div className="solar__about__media" style={{ order: 1 }}>
          <img
            src={require(`../../assets/solar/design-desktop.png`)}
            alt="about"
          />
        </div>

        <motion.div
          className="solar__about__info"
          initial={{
            y: "20%",
            opacity: 0,
          }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <div className="solar__about__info__text">
            {/* <h3>Efficiency</h3> */}
            <h2>Designed specifically for You</h2>
            <p>
              No one size fits all packages. We design the perfect system for your unique energy requirements and confidently deliver it. All the bang for all your buck.
            </p>
          </div>

          <div className="solar__about__info__btn center">
          <Link
                activeClass="active"
                to="contacts"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {1000}
            > 
              <button className="btn-secondary">ORDER NOW</button>
            </Link>
          </div>
        </motion.div>
      </div>
    } 

      <div className="solar__section">
        <div
          className="solar__section__cover"
          style={{
            background: "#000",
          }}
        >

          
        </div>

        <SectionSpecs
          about={solar.control_type}
          title={solar.control_title}
          description={solar.control_info}
        />
      </div>

      <div className="solar__about">
        <div className="solar__about__media" style={{ order: 1 }}>
          <img
            src={require(`../../assets/solar/piggy-bank.jpg`)}
            alt="about"
          />
        </div>

        <motion.div
          className="solar__about__info"
          initial={{
            y: "20%",
            opacity: 0,
          }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <div className="solar__about__info__text">
            <h2>Pay Less for Electricity</h2>
            <p>
              Solar Panels help pay for themselves with
              the energy you produce. Power your home at the lowest price per
              watt and take control of your electricity bill.
            </p>
          </div>

          <div className="solar__about__info__btn">
           
            <Link
                activeClass="active"
                to="contacts"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {1000}
            > <button className="btn-secondary">ORDER NOW</button>
              
            </Link>
          </div>
        </motion.div>
      </div>



      <div className="solar__footer" id="contacts">
        <div className="solar__footer__container">
          <h2>{solar.footer_title}</h2>
          <p>Talk to a Solaris Advisor</p>
          <div className="solar__footer__container__btns">
            <a href="tel:+2348100443121"><button className="btn-primary">CALL NOW</button></a>
            <br />
            <a href="https://wa.me/2348100443121"><button className="btn-primary">WHATSAPP NOW</button></a>
          </div>
        </div>

        <div className="solar__footer__container">
          <div className="solar__footer__container__btns">
            <a href="tel:2348100443121"><h1>08100443121</h1></a>
            <br />
            <a href="mailto:info@solarisenergy.ng"><h4>info@solarisenergy.ng</h4></a>
          </div>
          <div className="socials">  
            <div>
              <a href="https://www.instagram.com/solarisenergy.ng/">
                <img alt="social icons" src={require(`../../assets/solar/instagram-logo.png`)}/>
              </a>            
            </div>
            <div>
              <a href="https://www.facebook.com/SolarisSolarLtd">
                <img alt="social icons" src={require(`../../assets/solar/facebook-logo.png`)}/>
              </a>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

const SpecsDetailsItem = ({ title, specs }) => {
  return (
    <div className="solar__specs__container__specs__details__item">
      <p className="heading">{title}</p>
      <p>{specs}</p>
    </div>
  );
};

const Brands = ()=>{
  return (
    <div className="brands">
      <div>
        <img alt="brands" src={require(`../../assets/solar/techfine.webp`)}/>
      </div>
      <div>
        <img alt="brands" src={require(`../../assets/solar/luminous.png`)}/>
      </div>
      <div>
        <img alt="brands" src={require(`../../assets/solar/felicity.png`)}/>
      </div>
    </div>
  )
}
const SectionSpecs = ({ about, title, description, divStyle, orderNow, brands}) => {
  return (
    <div className="solar__section__specs" style={divStyle}>
      <motion.div
        className="solar__section__specs__div"
        initial={{
          y: "100%",
          opacity: 0,
        }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        
          <div className="solar__section__specs__div__main">
            <p>{about}</p>
            <h2>{title}</h2>
            {orderNow && 
              <Link
              activeClass="active"
              to="contacts"
              spy={true}
              smooth={true}
              offset={-70}
              duration= {1000}
          >
             <button className="btn-secondary">ORDER NOW</button>
          </Link>
           }
          </div>
        

        <div className="solar__section__specs__div__secondary">
          {brands ? <Brands/> : <p>{description}</p>}
        </div>
      </motion.div>
    </div>
  );
};

const SpecsItem = ({ title, label, delay, svg }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1, delay: delay }}
      className="solar__hero__specs__item"
    >
      <h2>{title}</h2>
      <p>{label}</p>
    </motion.div>
  );
};

export default Solar;
