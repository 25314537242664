export const getHomeData = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/home.json`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Error when fetching home data!");
    }

    const data = await response.json();

    let products = [];
    for (const key in data) {
      products.push({
        id: key,
        name: data[key].name,
        description: data[key].description,
        img_desktop: data[key].img_desktop,
        img_mobile: data[key].img_mobile,
      });
    }

    return products;
  } catch (error) {
    console.error(error);
  }
};

export const getCarModel = async (id) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API}/items/${id}.json`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Error when fetching car model data!");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getSolarModel = async (id) => {
  // try {
  //   const response = await fetch(
  //     `${process.env.REACT_APP_API}/solar/${id}.json`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   );

  //   if (!response.ok) {
  //     throw new Error("Error when fetching solar model data!");
  //   }

  //   const data = await response.json();

  //   return data;
  // } catch (error) {
  //   console.error(error);
  // }

  const solar = {
    solarpanels: {
      about_img_desktop: "panels-about-desktop.jpg",
      about_img_mobile: "panels-about-mobile.jpg",
      about_info:
        "Keep your home or business running 24/7 with solar energy and reduce your dependence on unreliable power sources. With Solaris’ Best Price Guarantee, you’ll purchase solar at the lowest price in Nigeria. Schedule a free consultation to learn more.",
      about_title: "Save big on Electricity",
      about_type: "Design",
      certification: "IEC / UL 61730, CEC Listed",
      control_img: "panels-control.jpg",
      control_info:
        "Manage your solar system from anywhere in the world with 24/7 mobile monitoring. Watch your energy in real time or set your preferences to optimize for energy independence.",
      control_title: "24 / 7 Monitoring",
      control_type: "Experience",
      description: "Providing Lowest Cost Solar Power in Nigeria at Highest Quality Since 2019",
      design: "Black sollar cells and aluminium frame",
      dimensions: '1640 x 992 x 70 MM',
      fire: "Class A (highest rating)",
      footer_title: "Power Your Home or Business",
      heading: "Powering Your Peace of Mind",
      hero_img_desktop: "close-up-solar-panel.mp4",
      hero_img_mobile: "panels-hero-mobile.jpg",
      isDark: true,
      name: "Solar Panel",
      power: "1kW to 100kW, 97.5% efficiency",
      protection_info:
        "Use solar energy to power your home and reduce your dependence on the grid. Purchase solar at the lowest price of any national provider with Tesla's price match guarantee and take control of your monthly electricity bill. Learn more about your potential savings in our Design Studio.",
      protection_title: "Electricity For Less",
      protection_type: "Savings",
      protection_video: "panels-protection.mp4",
      specs_img: "panels-specs.png",
      temperature: "-45°C up to 80°C",
      warranty: "25-year performance guarantee",
      wattage: "300W to 600W",
    },
    solarroof: {
      about_img_desktop: "roof-about-desktop.jpg",
      about_img_mobile: "roof-about-mobile.jpg",
      about_info:
        "Powerwall is a compact home battery that is bundled with every Tesla solar purchase, providing you with 24/7 energy security. It stores the energy you produce with Solar Roof, so you can power your home anytime—at night or during an outage.",
      about_title: "Backup Protection",
      about_type: "Energy Storage",
      certification: "IEC 61215",
      control_img: "roof-control.jpg",
      control_info:
        "With the Tesla app, you can monitor your energy production in real time. Control your system from anywhere with instant alerts and remote access.",
      control_title: "Monitor and Optimize",
      control_type: "Control",
      description: "Transform your roof and produce clean energy",
      design: "Light gray sollar cells and aluminium backsheet",
      dimensions: '52.8" x 35.5" x 3.85"',
      fire: "Class A (highest rating)",
      footer_title: "Transform Your Roof",
      heading: "Solar Roof",
      hero_img_desktop: "roof-hero-desktop.jpg",
      hero_img_mobile: "roof-hero-mobile.jpg",
      isDark: true,
      name: "Solar Roof",
      power: "3.8kW / 7.6kW 97.5% efficiency",
      protection_info:
        "Solar Roof tiles are durable, strong and engineered for all-weather protection. With a 25-year warranty, Solar Roof tiles will continue to produce clean energy for your home for decades to come.",
      protection_title: "Built to Last",
      protection_type: "Durability",
      protection_video: "roof-protection.mp4",
      specs_img: "roof-specs.jpg",
      temperature: "-25°F up to +205°F",
      warranty: "25-year performance guarantee",
      wattage: "375 W",
    },
  };

  return solar.solarpanels;
};
