import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { reviews } from './reviewsData';
import Quote from '../../assets/blockquote.svg';

const Testimonials = () => {
  return (
    <div className="testimonial-container py-4">
      <div className="title mb-3">
        <h1>Testimonials</h1>
      </div>

      <div className="slider-container my-2">
       <Splide
          options={{
            perPage: 1,
            autoplay: true,
            speed: 2000,
            rewind: true,
            rewindByDrag: true,
            arrows: false,
            pagination: false 
          }}
        >
          {reviews.map((review) => (
            <SplideSlide key={review.id}>
              <img className="review-img" src={review.image} alt="" />
              <div className="content mb-1">
                <p className="text mb-1">{review.text}</p>
                <div className="info ">
                  <div className="rating">
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                    <span className="star">&#9733;</span>
                  </div>
                  <p className="user mt-3">{review.name}</p>
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

export default Testimonials;